import React from "react";
import Header from "../Header";
import Loader from "../Loader";
import {
  Paper,
  Typography,
  Container,
  Box,
  Card,
  CardContent,
  CardActions,
  Chip,
  Grid,
  Button,
  Divider,
  DialogActions,
} from "@mui/material";
import axios from "axios";
import Cookie from "js-cookie";
import DesktopMenu from "../DesktopMenu";
import commonData from "../../importanValue";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EditExam from "../common/EditExam";
import ManageExamQuestions from "../common/ManageExamQuestions";
import QuizIcon from '@mui/icons-material/Quiz';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

class UserExams extends React.Component {
  state = {
    isLoading: false,
    exams: [],
    page: 0,
    hasMore: true,
    examsPerPage: 10,
    showEditDialog: false,
    editExamId: null,
    showQuestionsDialog: false,
    selectedExamId: null,
    showDeleteDialog: false,
    examToDelete: null,
  };

  componentDidMount() {
    this.fetchUserExams();
  }

  fetchUserExams = async () => {
    const token = Cookie.get("jwt_token");
    const { page } = this.state;

    try {
      this.setState({ isLoading: true });
      const response = await axios.get(
        `${commonData["api"]}/moderator/get-exams?page=${page}`,
        { headers: { authorization: token } }
      );

      this.setState({
        exams: response.data,
        hasMore: response.data.length === this.state.examsPerPage,
        isLoading: false,
      });
    } catch (error) {
      NotificationManager.error("Failed to fetch exams");
      this.setState({ isLoading: false });
    }
  };

  handleEditClick = (examId) => {
    this.setState({
      editExamId: examId,
      showEditDialog: true,
    });
  };

  handleEditClose = (wasUpdated) => {
    this.setState({
      showEditDialog: false,
      editExamId: null,
    });
    if (wasUpdated) {
      this.fetchUserExams();
    }
  };

  handleManageQuestions = (examId) => {
    console.log("🚀 ~ UserExams ~ examId:", examId)
    this.setState({
      showQuestionsDialog: true,
      selectedExamId: examId,
    });
  };

  handleQuestionsDialogClose = () => {
    this.setState({
      showQuestionsDialog: false,
      selectedExamId: null,
    });
  };

  handlePrevPage = () => {
    this.setState(
      (prevState) => ({
        page: prevState.page > 1 ? prevState.page - 1 : 1,
      }),
      this.fetchUserExams
    );
  };

  handleNextPage = () => {
    if (this.state.hasMore) {
      this.setState(
        (prevState) => ({
          page: prevState.page + 1,
        }),
        this.fetchUserExams
      );
    }
  };

  handleDeleteClick = (examId) => {
    this.setState({
      showDeleteDialog: true,
      examToDelete: examId,
    });
  };

  handleCloseDeleteDialog = () => {
    this.setState({
      showDeleteDialog: false,
      examToDelete: null,
    });
  };

  handleConfirmDelete = async () => {
    const token = Cookie.get("jwt_token");
    const uid = localStorage.getItem("num");
    const { examToDelete } = this.state;

    try {
      await axios.post(
        `${commonData["api"]}/support`,
        {
          type: "deleteExam",
          qid: examToDelete,
          search: uid,
        },
        {
          headers: {
            authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      NotificationManager.success("Exam deleted successfully");
      this.handleCloseDeleteDialog();
      this.fetchUserExams();
    } catch (error) {
      console.error("Delete error:", error);
      NotificationManager.error("Failed to delete exam");
    }
  };

  formatDateTime = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  render() {
    const { isLoading, exams, page, hasMore } = this.state;

    return (
      <div className="desktopsidebar">
        <div className="homedesktopsidebarmenuexamdetails">
          <DesktopMenu />
        </div>
        <Header />
        <div dangerouslySetInnerHTML={{__html: '<hr />'}} />

        <Container maxWidth="lg">
          <Box sx={{ mb: 4 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <div dangerouslySetInnerHTML={{
                  __html: `
                    <h4 style="color: #1976d2; font-weight: 600; margin-bottom: 8px;">My Exams</h4>
                    <div style="color: #666;">Create and manage your exams</div>
                  `
                }} />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => this.setState({ showEditDialog: true, editExamId: 0 })}
                  sx={{ borderRadius: 2, px: 3, py: 1, boxShadow: 2 }}
                >
                  Add Exam
                </Button>
              </Grid>
            </Grid>
          </Box>

          {isLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center", py: 8 }}>
              <Loader />
            </Box>
          ) : (
            <>
              <Grid container spacing={3}>
                {exams.map((exam) => (
                  <Grid item xs={12} md={6} key={exam.qid}>
                    <Card sx={{
                      borderRadius: 2,
                      boxShadow: "0 4px 12px rgba(0,0,0,0.08)",
                      transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                      "&:hover": {
                        transform: "translateY(-2px)",
                        boxShadow: "0 6px 16px rgba(0,0,0,0.12)"
                      }
                    }}>
                      <CardContent>
                        <div dangerouslySetInnerHTML={{
                          __html: `
                            <h3 style="margin-bottom: 16px;">${exam.quiz_name}</h3>
                            <div style="color: #666;">${exam.description}</div>
                          `
                        }} style={{height:100 ,overflowY:"scroll"}}/>
                      </CardContent>
                      <CardActions sx={{ justifyContent: "flex-end", p: 2 }}>
                        <Button
                          variant="outlined"
                          startIcon={<DeleteIcon />}
                          onClick={() => this.handleDeleteClick(exam.quid)}
                          size="small"
                          sx={{ borderRadius: 2, textTransform: "none", mr: 1 }}
                          color="error"
                        >
                          Delete
                        </Button>
                        <Button
                          variant="outlined"
                          startIcon={<QuizIcon />}
                          onClick={() => this.handleManageQuestions(exam.quid)}
                          size="small"
                          sx={{ borderRadius: 2, textTransform: "none", mr: 1 }}
                        >
                          Manage Questions
                        </Button>
                        <Button
                          variant="outlined"
                          startIcon={<EditIcon />}
                          onClick={() => this.handleEditClick(exam.quid)}
                          size="small"
                          sx={{ borderRadius: 2, textTransform: "none" }}
                        >
                          Edit Exam
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 4, mb: 2 }}>
                <Button
                  variant="outlined"
                  disabled={page === 1}
                  onClick={this.handlePrevPage}
                  sx={{ minWidth: 120, borderRadius: 2 }}
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  disabled={!hasMore}
                  onClick={this.handleNextPage}
                  sx={{ minWidth: 120, borderRadius: 2 }}
                >
                  Next
                </Button>
              </Box>
            </>
          )}
          <EditExam
            open={this.state.showEditDialog}
            examId={this.state.editExamId}
            onClose={this.handleEditClose}
          />
          <ManageExamQuestions
            open={this.state.showQuestionsDialog}
            examId={this.state.selectedExamId}
            onClose={this.handleQuestionsDialogClose}
          />
          <Dialog 
            open={this.state.showDeleteDialog} 
            onClose={this.handleCloseDeleteDialog}
            maxWidth="xs"
            fullWidth
          >
            <DialogTitle>Delete Exam</DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to delete this exam? This action cannot be undone.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseDeleteDialog}>Cancel</Button>
              <Button onClick={this.handleConfirmDelete} color="error">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <NotificationContainer />
        </Container>
      </div>
    );
  }
}

export default UserExams;
