import { Redirect, Route } from "react-router-dom";
import Cookie from "js-cookie";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import commonData from "../importanValue";
import axios from "axios";
import Loader from "./Loader";

const ProtectedRoute = (props) => {
  const token = Cookie.get("jwt_token");
  const location = useLocation();
  const uid = localStorage.getItem("num");

  const [loading, setLoading] = useState(true); // To handle asynchronous operations
  const [showSelection, setShowSelection] = useState(false);
  const [profileIncomplete, setProfileIncomplete] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        setLoading(true);

        const headers = {
          "Content-Type": "application/json",
          authorization: token,
          "Access-Control-Allow-Origin": "*",
        };

        const userDetails = await axios.post(
          `${commonData["api"]}/get-user-details/${uid}`,
          {}, // Empty payload
          { headers } // Pass headers in the correct position
        );

        if (userDetails) {
          const userInfo = userDetails.data.result[0];
          console.log("🚀 ~ fetchUserDetails ~ userDetails:", userDetails)
          console.log("🚀 ~ fetchUserDetails ~ userInfo:", userInfo)
          const subStr = userInfo.subjects_selected;
          const enable_english_medium = userInfo.enable_english_medium;

          // Save relevant info in localStorage
          localStorage.setItem("enable_english_medium", enable_english_medium);
          localStorage.setItem("user", userInfo.su);

          // Set state based on user details
          setShowSelection(subStr === "");
          if (
            !userInfo.first_name ||
            !userInfo.last_name ||
            !userInfo.district
          ) {
            window.location.href = "/user-profile?incomplete=true";
          }
        }
      } catch (error) {
        console.error("Error fetching user details:", error.message);
      } finally {
        setLoading(false); // Ensure loading ends
      }
    };

    if (
      token !== undefined &&
      !location.pathname.includes("select-the-exams") &&
      !location.pathname.includes("/user-profile")
    ) {
      fetchUserDetails();
    } else {
      setLoading(false);
    }
  }, [uid, JSON.stringify(location)]);

  // Redirect logic
  if (token === undefined) {
    if (
      String(location.search).includes("?refer=") &&
      localStorage.getItem("refer") === undefined
    ) {
      localStorage.setItem("refer", location.search.split("?refer=")[1]);
    }
    return <Redirect to={`/login/?next=${location.pathname}`} />;
  } else if (loading) {
    return (
      <div className="loader-main-container">
        <Loader />
      </div>
    );
  } else if (profileIncomplete) {
    return <Redirect to={"/user-profile?incomplete=true"} />;
  } else if (showSelection) {
    return <Redirect to={`/select-the-exams?next=${location.pathname}`} />;
  } else {
    return <Route {...props} />;
  }
};

export default ProtectedRoute;
