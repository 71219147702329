import React from "react";
import Header from "../Header";
import Loader from "../Loader";
import { Divider, Button, TextField } from "@mui/material";
import axios from "axios";
import Cookie from "js-cookie";
import DesktopMenu from "../DesktopMenu";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import commonData from "../../importanValue";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Editor from "../admin/Editor";
import EditQuestion from "../common/EditQuestion";
import {
  Paper,
  Typography,
  Container,
  Box,
  IconButton,
  Card,
  CardContent,
  CardActions,
  Chip,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

class UserQuestions extends React.Component {
  state = {
    isLoading: false,
    questions: [],
    popUpOpen: false,
    questionText: "",
    questionEditorContent: "",
    op1: "",
    op1EditorContent: "",
    op2: "",
    op2EditorContent: "",
    op3: "",
    op3EditorContent: "",
    op4: "",
    op4EditorContent: "",
    correctOption: 1,
    selectedCategory: "",
    categories: [],
    page: 0,
    hasMore: true,
    questionsPerPage: 10,
    isEdit: false,
    editQuestionId: null,
    showEditDialog: false,
    englishMedium: false,
  };

  componentDidMount() {
    this.fetchUserQuestions();
    this.fetchCategories();
  }

  fetchUserQuestions = async () => {
    const token = Cookie.get("jwt_token");
    const uid = localStorage.getItem("num");
    const { page, questionsPerPage } = this.state;
    const headers = {
      authorization: token,
    };

    try {
      this.setState({ isLoading: true });
      const response = await axios.get(
        `${commonData["api"]}/moderator/get-questions?page=${page}`,
        { headers: { authorization: token } }
      );
      this.setState({
        questions: response.data,
        hasMore: response.data.length === this.state.questionsPerPage,
        isLoading: false,
      });
    } catch (error) {
      NotificationManager.error("Failed to fetch questions");
      this.setState({ isLoading: false });
    }
  };

  fetchCategories = async () => {
    const token = Cookie.get("jwt_token");
    const headers = {
      authorization: token,
    };

    try {
      const response = await axios.post(
        `${commonData["api"]}/support`,
        {
          type: "getCategories",
          search: "",
          qid: 0,
        },
        { headers }
      );
      this.setState({ categories: response.data[0] });
    } catch (error) {
      NotificationManager.error("Failed to fetch categories");
    }
  };

  handleAddQuestion = async () => {
    const {
      questionEditorContent,
      op1EditorContent,
      op2EditorContent,
      op3EditorContent,
      op4EditorContent,
      correctOption,
      selectedCategory,
    } = this.state;
    const token = Cookie.get("jwt_token");
    const uid = localStorage.getItem("num");
    const headers = {
      authorization: token,
    };

    // Validation helper
    const stripHtml = (html) => html?.replace(/<[^>]*>/g, "").trim();
    const isEmptyHtml = (html) => !stripHtml(html);

    // Validate all fields
    if (isEmptyHtml(questionEditorContent)) {
      NotificationManager.error("Please enter the question");
      return;
    }
    if (isEmptyHtml(op1EditorContent)) {
      NotificationManager.error("Please enter option 1");
      return;
    }
    if (isEmptyHtml(op2EditorContent)) {
      NotificationManager.error("Please enter option 2");
      return;
    }
    if (isEmptyHtml(op3EditorContent)) {
      NotificationManager.error("Please enter option 3");
      return;
    }
    if (isEmptyHtml(op4EditorContent)) {
      NotificationManager.error("Please enter option 4");
      return;
    }
    if (!selectedCategory) {
      NotificationManager.error("Please select a category");
      return;
    }

    try {
      this.setState({ isLoading: true });
      await axios.post(
        `${commonData["api"]}/support`,
        {
          type: "addUserQuestion",
          uid,
          questionText: questionEditorContent,
          op1: op1EditorContent,
          op2: op2EditorContent,
          op3: op3EditorContent,
          op4: op4EditorContent,
          correctOption,
          category: selectedCategory,
        },
        { headers }
      );

      NotificationManager.success("Question added successfully");
      this.setState({
        popUpOpen: false,
        questionText: "",
        questionEditorContent: "",
        op1: "",
        op1EditorContent: "",
        op2: "",
        op2EditorContent: "",
        op3: "",
        op3EditorContent: "",
        op4: "",
        op4EditorContent: "",
        correctOption: 1,
      });
      this.fetchUserQuestions();
    } catch (error) {
      NotificationManager.error("Failed to add question");
      this.setState({ isLoading: false });
    }
  };

  handleEditClick = (questionId, englishMedium) => {
    this.setState({
      editQuestionId: questionId,
      showEditDialog: true,
      englishMedium,
    });
  };

  handleEditClose = (wasUpdated) => {
    this.setState({
      showEditDialog: false,
      editQuestionId: null,
    });
    if (wasUpdated) {
      this.fetchUserQuestions();
    }
  };

  handleSubmit = () => {
    if (this.state.isEdit) {
      this.handleEditQuestion();
    } else {
      this.handleAddQuestion();
    }
  };

  handlePrevPage = () => {
    this.setState(
      (prevState) => ({
        page: prevState.page > 1 ? prevState.page - 1 : 1,
      }),
      this.fetchUserQuestions
    );
  };

  handleNextPage = () => {
    if (this.state.hasMore) {
      this.setState(
        (prevState) => ({
          page: prevState.page + 1,
        }),
        this.fetchUserQuestions
      );
    }
  };

  render() {
    const { isLoading, questions, page, hasMore } = this.state;

    return (
      <div className="desktopsidebar">
        <div className="homedesktopsidebarmenuexamdetails">
          <DesktopMenu />
        </div>
        <Header />
        <Divider />

        <Container maxWidth="lg" sx={{ py: 4 }}>
          <Box sx={{ mb: 4 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography
                  variant="h4"
                  sx={{
                    color: "#1976d2",
                    fontWeight: 600,
                    mb: 1,
                  }}
                >
                  My Questions
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "text.secondary" }}
                >
                  Create and manage your question bank
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() =>
                    this.setState({
                      showEditDialog: true,
                      editQuestionId: 0,
                      isEnglishMedium: false,
                    })
                  }
                  sx={{
                    borderRadius: 2,
                    px: 3,
                    py: 1,
                    boxShadow: 2,
                  }}
                >
                  Add Question
                </Button>
              </Grid>
            </Grid>
          </Box>

          {isLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center", py: 8 }}>
              <Loader />
            </Box>
          ) : (
            <>
              <Grid container spacing={3}>
                {questions.map((question) => (
                  <Grid item xs={12} key={question.qid}>
                    <Card
                      sx={{
                        borderRadius: 2,
                        boxShadow: "0 4px 12px rgba(0,0,0,0.08)",
                        transition:
                          "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                        "&:hover": {
                          transform: "translateY(-2px)",
                          boxShadow: "0 6px 16px rgba(0,0,0,0.12)",
                        },
                      }}
                    >
                      <CardContent>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 2 }}
                        >
                          <Chip
                            label={`ID: ${question.qid}`}
                            color="primary"
                            size="small"
                            sx={{ mr: 2 }}
                          />
                        </Box>
                        <Typography
                          variant="body1"
                          component="div"
                          sx={{
                            fontSize: "1rem",
                            lineHeight: 1.6,
                            color: "text.primary",
                            "& p": { margin: 0 },
                          }}
                          dangerouslySetInnerHTML={{
                            __html: question.question,
                          }}
                        />
                      </CardContent>
                      <CardActions sx={{ justifyContent: "flex-end", p: 2 }}>
                        <Button
                          variant="outlined"
                          startIcon={<EditIcon />}
                          onClick={() =>
                            this.handleEditClick(question.qid, true)
                          }
                          size="small"
                          sx={{
                            borderRadius: 2,
                            textTransform: "none",
                          }}
                        >
                          Edit EM Question
                        </Button>{" "}
                        <Button
                          variant="outlined"
                          startIcon={<EditIcon />}
                          onClick={() =>
                            this.handleEditClick(question.qid, false)
                          }
                          size="small"
                          sx={{
                            borderRadius: 2,
                            textTransform: "none",
                          }}
                        >
                          Edit TM Question
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 2,
                  mt: 4,
                  mb: 2,
                }}
              >
                <Button
                  variant="outlined"
                  disabled={page === 1}
                  onClick={this.handlePrevPage}
                  sx={{
                    minWidth: 120,
                    borderRadius: 2,
                  }}
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  disabled={!hasMore}
                  onClick={this.handleNextPage}
                  sx={{
                    minWidth: 120,
                    borderRadius: 2,
                  }}
                >
                  Next
                </Button>
              </Box>
            </>
          )}

          <EditQuestion
            open={this.state.showEditDialog}
            questionId={this.state.editQuestionId}
            onClose={this.handleEditClose}
            isEnglishMedium={this.state.englishMedium}
          />

          <NotificationContainer />
        </Container>
      </div>
    );
  }
}

export default UserQuestions;
