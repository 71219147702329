import React, { useState, useEffect } from "react";
import { Button, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Editor from "../admin/Editor";
import axios from "axios";
import Cookie from "js-cookie";
import commonData from "../../importanValue";
import { NotificationManager } from "react-notifications";
import { Box, Stack, Typography } from "@mui/material";

const EditExam = ({ open, examId, onClose }) => {
  const getDefaultStartDate = () => {
    // return todates date + 9:00:00
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    return `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day} 09:00:00`;
  };

  const [state, setState] = useState({
    isLoading: false,
    examName: "",
    examNameEditorContent: "",
    description: "",
    descriptionEditorContent: "",
    mode: "add",
    startDate: getDefaultStartDate(), // Set default date
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      mode: examId ? "edit" : "add",
      startDate: examId ? prev.startDate : getDefaultStartDate() // Reset to default for new exams
    }));

    if (open && examId && examId !== 0) {
      fetchExamDetails();
    }
  }, [open, examId]);

  useEffect(() => {
    if (!open) {
      setState({
        isLoading: false,
        examName: "",
        examNameEditorContent: "",
        description: "",
        descriptionEditorContent: "",
        mode: "add",
        startDate: getDefaultStartDate(),
      });
    }
  }, [open]);

  const fetchExamDetails = async () => {
    const token = Cookie.get("jwt_token");
    try {
      setState((prev) => ({ ...prev, isLoading: true }));
      const response = await axios.post(
        `${commonData["api"]}/support`,
        {
          type: "getExamDetails",
          qid: examId,
          search: "",
        },
        { headers: { authorization: token } }
      );

      const examData = response.data[0][0];

      setState((prev) => ({
        ...prev,
        examName: examData.quiz_name,
        examNameEditorContent: examData.quiz_name,
        description: examData.description,
        descriptionEditorContent: examData.description,
        startDate: examData.start_date || "",
        isLoading: false,
        mode: examId ? "edit" : "add",
      }));
    } catch (error) {
      console.error("Edit error:", error);
      NotificationManager.error("Failed to fetch exam details");
      setState((prev) => ({ ...prev, isLoading: false }));
    }
  };

  const processText = (text) => {
    return text.replace(/'/g, "`").replace(/�s/g, "`s");
  };

  const validateDateTime = (dateStr) => {
    if (!dateStr) return false;
    
    // Check format YYYY-MM-DD HH:mm:ss
    const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    if (!regex.test(dateStr)) return false;

    const [datePart, timePart] = dateStr.split(' ');
    const [year, month, day] = datePart.split('-');
    const [hours, minutes, seconds] = timePart.split(':');

    // Convert to numbers
    const y = parseInt(year), m = parseInt(month), d = parseInt(day);
    const h = parseInt(hours), min = parseInt(minutes), s = parseInt(seconds);

    // Validate ranges
    if (y < 2024 || m < 1 || m > 12 || d < 1 || d > 31) return false;
    if (h < 0 || h > 23 || min < 0 || min > 59 || s < 0 || s > 59) return false;

    // Validate against current date
    const inputDate = new Date(dateStr);
    if (isNaN(inputDate.getTime())) return false;
    
    return true;
  };

  const handleDateChange = (e) => {
    const dateStr = e.target.value;
    setState(prev => ({ 
      ...prev, 
      startDate: dateStr.replace('T', ' ') + ':00'
    }));
  };

  const handleSubmit = async () => {
    const token = Cookie.get("jwt_token");
    const uid = localStorage.getItem("num");

    try {
      const { examNameEditorContent, descriptionEditorContent, mode } = state;

      if (!examNameEditorContent.trim()) {
        NotificationManager.error("Please enter exam name");
        return;
      }

      const examPayload = {
        type: mode === "edit" ? "edit" : "add",
        examName: processText(examNameEditorContent),
        description: processText(descriptionEditorContent),
        examId: examId || 0,
        startDate: state.startDate,
        uid,
      };

      const response = await axios.post(
        `${commonData["api"]}/moderator/add-edit-exam`,
        examPayload,
        {
          headers: {
            authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data && !response.data.error) {
        NotificationManager.success(
          mode === "edit"
            ? "Exam updated successfully"
            : "Exam created successfully"
        );
        onClose(true);
      }
    } catch (error) {
      console.error(`${state.mode} error:`, error);
      NotificationManager.error(`Failed to ${state.mode} exam`);
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
        },
      }}
    >
      <DialogTitle
        sx={{
          pb: 2,
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
          {state.mode === "edit" ? "Edit Exam" : "Create New Exam"}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
          {state.mode === "edit"
            ? "Update exam details"
            : "Add exam details and save"}
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ py: 3 }}>
        <Stack spacing={3}>
          <Box>
            <Typography
              variant="subtitle2"
              sx={{ mb: 1, color: "text.primary", fontWeight: 500 }}
            >
              Exam Name
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter exam name"
              value={state.examNameEditorContent}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  examNameEditorContent: e.target.value,
                }))
              }
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 1,
                  bgcolor: "background.paper",
                  "& fieldset": {
                    borderColor: "divider",
                  },
                  "&:hover fieldset": {
                    borderColor: "primary.main",
                  },
                },
              }}
            />
          </Box>

          <Box>
            <Typography
              variant="subtitle2"
              sx={{ mb: 1, color: 'text.primary', fontWeight: 500 }}
            >
              Exam Start Date and Time
            </Typography>
            <TextField
              fullWidth
              placeholder="YYYY-MM-DD HH:mm:ss"
              value={state.startDate}
              onChange={(e) => setState(prev => ({ ...prev, startDate: e.target.value }))}
              error={state.startDate && !validateDateTime(state.startDate)}
              helperText={
                state.startDate && !validateDateTime(state.startDate) 
                  ? "Format: YYYY-MM-DD HH:mm:ss (e.g. 2024-03-27 09:00:00)" 
                  : "Enter date and time in format: YYYY-MM-DD HH:mm:ss"
              }
              inputProps={{
                style: { fontFamily: 'monospace' }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 1,
                  bgcolor: 'background.paper',
                  '& fieldset': { borderColor: 'divider' },
                  '&:hover fieldset': { borderColor: 'primary.main' },
                  '&.Mui-error fieldset': { borderColor: 'error.main' }
                }
              }}
            />
          </Box>

          <Box>
            <Typography
              variant="subtitle2"
              sx={{ mb: 1, color: "text.primary", fontWeight: 500 }}
            >
              Syllabus
            </Typography>
            <Box
              sx={{
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 1,
                overflow: "hidden",
              }}
            >
              <Editor
                initialValue={state.description}
                onEditorChange={(content) =>
                  setState((prev) => ({
                    ...prev,
                    descriptionEditorContent: content,
                  }))
                }
              />
            </Box>
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{
          px: 3,
          py: 2,
          borderTop: "1px solid",
          borderColor: "divider",
          gap: 1,
        }}
      >
        <Button
          onClick={() => onClose(false)}
          variant="outlined"
          sx={{
            borderRadius: 1,
            textTransform: "none",
            minWidth: 100,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{
            borderRadius: 1,
            textTransform: "none",
            minWidth: 100,
            boxShadow: "0 8px 16px rgba(25, 118, 210, 0.24)",
          }}
        >
          {state.mode === "edit" ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditExam;
