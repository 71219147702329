import React, { useEffect, useState } from "react";
import RichTextEditor from "react-rte";

const Editor = ({
  initialValue,

  onEditorChange,
}) => {
  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createEmptyValue()
  );

  useEffect(() => {
    if (initialValue) {
      setEditorValue(
        RichTextEditor.createValueFromString(initialValue, "html")
      );
    }
  }, [initialValue]);
  const handleChange = (value) => {
    setEditorValue(value);
    onEditorChange(value.toString("html"));
  };

  const defaultToolbarConfig = {
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      "BLOCK_TYPE_DROPDOWN",
      "HISTORY_BUTTONS",
      "IMAGE_BUTTON"
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
      // { label: "Superscript", style: "SUPERSCRIPT" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

  return (
    <RichTextEditor
      toolbarConfig={defaultToolbarConfig}
      value={editorValue}
      onChange={handleChange}
    />
  );
};

export default Editor;
