import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Box,
  Typography,
  List,
  ListItem,
  IconButton,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Chip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import Cookie from "js-cookie";
import commonData from "../../importanValue";
import { NotificationManager } from "react-notifications";
import Loader from "../Loader";

const StyledListItem = ({ question, index, onDelete }) => (
  <Paper
    elevation={0}
    sx={{
      mb: 2,
      p: 2,
      borderRadius: 2,
      border: '1px solid',
      borderColor: 'divider',
      '&:hover': {
        boxShadow: 1,
        bgcolor: 'background.paper'
      }
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
      <Typography 
        sx={{ 
          color: 'text.secondary',
          minWidth: '30px',
          pt: 0.5
        }}
      >
        {index + 1}.
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <div dangerouslySetInnerHTML={{ __html: question.question }} />
        <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
          Category ID: {question.cid}
        </Typography>
      </Box>
      <IconButton 
        onClick={() => onDelete(question.qid)}
        sx={{ 
          color: 'error.main',
          '&:hover': {
            bgcolor: 'error.lighter'
          }
        }}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  </Paper>
);

const ManageExamQuestions = ({ open, examId, onClose }) => {
  const [state, setState] = useState({
    isLoading: false,
    availableQuestions: [],
    selectedQuestions: [],
    existingQuestions: [],
    questionsPage: 0,
    hasMoreQuestions: true,
    questionsPerPage: 10,
    existingQuestionIds: [],
    showQuestionSelector: false, // Add this new state
  });

  useEffect(() => {
    if (open && examId) {
      fetchExistingQuestions();
      fetchAvailableQuestions();

      return () => {
        setState({
          isLoading: false,
          availableQuestions: [],
          selectedQuestions: [],
          existingQuestions: [],
          questionsPage: 0,
          hasMoreQuestions: true,
          existingQuestionIds: [],
        });
      };
    }
  }, [open, examId]);

  const fetchExistingQuestions = async () => {
    const token = Cookie.get("jwt_token");
    try {
      const response = await axios.post(
        `${commonData["api"]}/support`,
        {
          type: "getExamQuestions",
          qid: examId,
          search: "",
        },
        { headers: { authorization: token } }
      );

      // Set both existing questions and IDs
      setState((prev) => ({
        ...prev,
        existingQuestions: response.data[0], // Array of question objects
        existingQuestionIds: response.data[0].map((q) => q.qid.toString()), // Array of question IDs
      }));
    } catch (error) {
      NotificationManager.error("Failed to fetch exam questions");
    }
  };

  const fetchAvailableQuestions = async () => {
    const token = Cookie.get("jwt_token");

    try {
      setState((prev) => ({ ...prev, isLoading: true }));
      const response = await axios.get(
        `${commonData["api"]}/moderator/get-questions?page=${state.questionsPage}`,
        { headers: { authorization: token } }
      );

      setState((prev) => ({
        ...prev,
        availableQuestions: response.data,
        hasMoreQuestions: response.data.length === state.questionsPerPage,
        isLoading: false,
      }));
    } catch (error) {
      console.error("Failed to fetch questions:", error);
      NotificationManager.error("Failed to fetch questions");
      setState((prev) => ({ ...prev, isLoading: false }));
    }
  };

  const handleToggleQuestion = (questionId) => {
    setState((prev) => ({
      ...prev,
      selectedQuestions: prev.selectedQuestions.includes(questionId)
        ? prev.selectedQuestions.filter((id) => id !== questionId)
        : [...prev.selectedQuestions, questionId],
    }));
  };

  const handlePrevQuestionsPage = () => {
    setState((prev) => ({
      ...prev,
      questionsPage: prev.questionsPage > 0 ? prev.questionsPage - 1 : 0,
    }));
  };

  const handleNextQuestionsPage = () => {
    if (state.hasMoreQuestions) {
      setState((prev) => ({
        ...prev,
        questionsPage: prev.questionsPage + 1,
      }));
    }
  };

  useEffect(() => {
    if (open && examId) {
      fetchAvailableQuestions();
    }
  }, [state.questionsPage]); // Add questionsPage as dependency

  const handleAddQuestions = async () => {
    const token = Cookie.get("jwt_token");
    const uid = localStorage.getItem("num");

    try {
      if (state.selectedQuestions.length === 0) {
        NotificationManager.warning("Please select questions to add");
        return;
      }

      await axios.post(
        `${commonData["api"]}/moderator/add-questions-to-exam`,
        {
          examId,
          qids: state.selectedQuestions.join(","),
          uid,
        },
        {
          headers: {
            authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      NotificationManager.success("Questions added successfully");
      fetchExistingQuestions();
      setState((prev) => ({ ...prev, selectedQuestions: [] }));
    } catch (error) {
      NotificationManager.error("Failed to add questions");
    }
  };

  const handleDeleteQuestion = async (qid) => {
    const token = Cookie.get("jwt_token");
    const uid = localStorage.getItem("num");

    try {
      await axios.post(
        `${commonData["api"]}/moderator/add-questions-to-exam`,
        {
          examId,
          qids: state.existingQuestionIds.filter((e) => e != qid).join(","),
          uid,
        },
        {
          headers: {
            authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      NotificationManager.success("Questions removed successfully");
      fetchExistingQuestions();
      setState((prev) => ({ ...prev, selectedQuestions: [] }));
    } catch (error) {
      NotificationManager.error("Failed to add questions");
    }
  };

  const handleShowQuestionSelector = () => {
    setState((prev) => ({
      ...prev,
      showQuestionSelector: true,
      selectedQuestions:prev.existingQuestionIds
    }));
  };

  const handleHideQuestionSelector = () => {
    setState((prev) => ({
      ...prev,
      showQuestionSelector: false,
      selectedQuestions: [],
    }));
  };

  const renderQuestionList = () => (
    <Box>
      <Box 
        sx={{ 
          display: "flex", 
          justifyContent: "space-between", 
          alignItems: 'center',
          mb: 3 
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 500 }}>
          Select Questions
        </Typography>
        <Button
          variant="outlined"
          size="small"
          startIcon={<ArrowBackIcon />}
          onClick={handleHideQuestionSelector}
        >
          Back to List
        </Button>
      </Box>

      {/* Question Selection Grid */}
      <Grid container spacing={2}>
        {state.availableQuestions.map((question) => (
          <Grid item xs={12} key={question.qid}>
            <Paper
              sx={{
                p: 2.5,
                borderRadius: 2,
                bgcolor: state.existingQuestionIds.includes(question.qid.toString())
                  ? 'success.lighter'
                  : 'background.paper',
                border: '1px solid',
                borderColor: 'divider'
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.selectedQuestions.includes(question.qid)}
                    onChange={() => handleToggleQuestion(question.qid)}
                    disabled={state.existingQuestionIds.includes(question.qid.toString())}
                  />
                }
                label={
                  <Box>
                    <div dangerouslySetInnerHTML={{ __html: question.question }} />
                    {state.existingQuestionIds.includes(question.qid.toString()) && (
                      <Chip
                        label="Already Added"
                        color="success"
                        size="small"
                        sx={{ mt: 1 }}
                      />
                    )}
                  </Box>
                }
              />
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Actions */}
      <Box 
        sx={{ 
          display: "flex", 
          justifyContent: "space-between", 
          mt: 4,
          pt: 2,
          borderTop: 1,
          borderColor: 'divider'
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddQuestions}
          disabled={state.selectedQuestions.length === 0}
          startIcon={<AddIcon />}
        >
          Add {state.selectedQuestions.length} Questions
        </Button>

        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            variant="outlined"
            disabled={state.questionsPage === 0}
            onClick={handlePrevQuestionsPage}
          >
            Previous
          </Button>
          <Button
            variant="outlined"
            disabled={!state.hasMoreQuestions}
            onClick={handleNextQuestionsPage}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );

  const renderExistingQuestions = () => (
    <>
      <Box 
        sx={{ 
          display: "flex", 
          justifyContent: "space-between", 
          alignItems: 'center',
          mb: 3 
        }}
      >
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            Exam Questions
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {state.existingQuestions.length} questions added
          </Typography>
        </Box>
        <Button
          variant="contained"
          onClick={handleShowQuestionSelector}
          startIcon={<AddIcon />}
        >
          Add Questions
        </Button>
      </Box>

      {state.existingQuestions.length > 0 ? (
        <Box>
          {state.existingQuestions.map((question, index) => (
            <StyledListItem 
              key={question.qid}
              question={question}
              index={index}
              onDelete={handleDeleteQuestion}
            />
          ))}
        </Box>
      ) : (
        <Paper
          sx={{
            p: 6,
            textAlign: 'center',
            borderRadius: 2,
            bgcolor: 'background.neutral'
          }}
        >
          <Typography color="text.secondary" paragraph>
            No questions added yet
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleShowQuestionSelector}
          >
            Add Your First Question
          </Button>
        </Paper>
      )}
    </>
  );

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>
        {state.showQuestionSelector ? "Add Questions" : "Manage Exam Questions"}
      </DialogTitle>
      <DialogContent>
        {state.isLoading ? (
          <Loader />
        ) : state.showQuestionSelector ? (
          renderQuestionList()
        ) : (
          renderExistingQuestions()
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageExamQuestions;
