import React from "react";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Editor from "../admin/Editor";
import axios from "axios";
import Cookie from "js-cookie";
import commonData from "../../importanValue";
import { NotificationManager } from "react-notifications";

class EditQuestion extends React.Component {
  state = {
    isLoading: false,
    questionText: "",
    questionEditorContent: "",
    op1: "",
    op1EditorContent: "",
    op2: "",
    op2EditorContent: "",
    op3: "",
    op3EditorContent: "",
    op4: "",
    op4EditorContent: "",
    correctOption: 1,
    selectedCategory: "",
    categories: [],
    mode: "add", // new state property
    optionIds: [], // Store option IDs in state
    explanation: "",
    explanationEditorContent: "",
    isEnglishMedium: false,
  };
  componentDidMount() {
    // Update mode based on questionId existence
    this.setState(
      {
        mode: this.props.questionId ? "edit" : "add",
        questionText: "",
        questionEditorContent: "",
        op1: "",
        op1EditorContent: "",
        op2: "",
        op2EditorContent: "",
        op3: "",
        op3EditorContent: "",
        op4: "",
        op4EditorContent: "",
        correctOption: 1,
        selectedCategory: "",
        categories: [],
        isEnglishMedium: this.props.isEnglishMedium,
      },
      () => {
        if (this.props.open) {
          if (this.props.questionId && this.props.questionId != 0) {
            this.fetchQuestionDetails();
          }
          this.fetchCategories();
        }
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      if (this.props.questionId && this.props.questionId != 0) {
        if (this.props.isEnglishMedium) {
          this.fetchEnglishQuestionDetails();
        } else {
          this.fetchQuestionDetails();
        }
      }
      this.fetchCategories();
    }
    // Add check for isEnglishMedium prop change
    if (
      prevProps.isEnglishMedium !== this.props.isEnglishMedium &&
      this.props.open
    ) {
      this.setState({ isEnglishMedium: this.props.isEnglishMedium }, () => {
        if (this.props.questionId && this.props.questionId != 0) {
          if (this.props.isEnglishMedium) {
            this.fetchEnglishQuestionDetails();
          } else {
            this.fetchQuestionDetails();
          }
        }
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      isLoading: false,
      questionText: "",
      questionEditorContent: "",
      op1: "",
      op1EditorContent: "",
      op2: "",
      op2EditorContent: "",
      op3: "",
      op3EditorContent: "",
      op4: "",
      op4EditorContent: "",
      correctOption: 1,
      selectedCategory: "",
      categories: [],
      mode: "add", // new state property
      optionIds: [],
      explanation: "",
      explanationEditorContent: "",
      isEnglishMedium: false,
    });
  }

  fetchQuestionDetails = async () => {
    const token = Cookie.get("jwt_token");
    const headers = { authorization: token };

    try {
      this.setState({ isLoading: true });
      const response = await axios.post(
        `${commonData["api"]}/support`,
        {
          type: this.props.isEnglishMedium
            ? "getEnglishQuestionDetails"
            : "getQuestionDetails",
          qid: this.props.questionId,
          search: "",
        },
        { headers }
      );

      const questionData = JSON.parse(response.data[0][0].questionDetails);
      const options = questionData.options;
      const correctOption = options.findIndex((opt) => opt.isCorrect === 1) + 1;

      // Store option IDs correctly
      const optionIds = options.map((opt) => opt.optionid);

      this.setState({
        questionText: questionData.questionText,
        questionEditorContent: questionData.questionText,
        op1: options[0].optionText,
        op1EditorContent: options[0].optionText,
        op2: options[1].optionText,
        op2EditorContent: options[1].optionText,
        op3: options[2].optionText,
        op3EditorContent: options[2].optionText,
        op4: options[3].optionText,
        op4EditorContent: options[3].optionText,
        correctOption,
        selectedCategory: questionData.cid,
        optionIds, // Store option IDs in state
        isLoading: false,
        mode: this.props.questionId ? "edit" : "add",
        explanation: questionData.description || "",
        explanationEditorContent: questionData.description || "",
      });
    } catch (error) {
      console.error("Edit error:", error);
      NotificationManager.error("Failed to fetch question details");
      this.setState({ isLoading: false });
    }
  };

  fetchEnglishQuestionDetails = async () => {
    const token = Cookie.get("jwt_token");
    const headers = { authorization: token };

    try {
      this.setState({ isLoading: true });
      const response = await axios.post(
        `${commonData["api"]}/support`,
        {
          type: "getEnglishQuestionDetails",
          qid: this.props.questionId,
          search: "",
        },
        { headers }
      );

      const questionData = JSON.parse(response.data[0][0].questionDetails);
      const options = questionData.options;
      const correctOption = options.findIndex((opt) => opt.isCorrect === 1) + 1;
      const optionIds = options.map((opt) => opt.optionid);

      this.setState({
        questionText: questionData.questionText,
        questionEditorContent: questionData.questionText,
        op1: options[0].optionText,
        op1EditorContent: options[0].optionText,
        op2: options[1].optionText,
        op2EditorContent: options[1].optionText,
        op3: options[2].optionText,
        op3EditorContent: options[2].optionText,
        op4: options[3].optionText,
        op4EditorContent: options[3].optionText,
        correctOption,
        selectedCategory: questionData.cid,
        optionIds,
        isLoading: false,
        mode: this.props.questionId ? "edit" : "add",
        explanation: questionData.description || "",
        explanationEditorContent: questionData.description || "",
      });
    } catch (error) {
      console.error("Edit error:", error);
      NotificationManager.error("Failed to fetch English question details");
      this.setState({ isLoading: false });
    }
  };

  fetchCategories = async () => {
    const token = Cookie.get("jwt_token");
    const headers = { authorization: token };

    try {
      const response = await axios.post(
        `${commonData["api"]}/support`,
        {
          type: "getCategories",
          search: "",
          qid: 0,
        },
        { headers }
      );
      this.setState({ categories: response.data[0] });
    } catch (error) {
      NotificationManager.error("Failed to fetch categories");
    }
  };

  processText = (text) => {
    return text.replace(/'/g, "`").replace(/�s/g, "`s");
  };

  handleSubmit = async () => {
    const token = Cookie.get("jwt_token");
    const uid = localStorage.getItem("uid");
    const num = localStorage.getItem("num");

    try {
      const {
        questionEditorContent,
        op1EditorContent,
        op2EditorContent,
        op3EditorContent,
        op4EditorContent,
        correctOption,
        selectedCategory,
        optionIds,
        mode,
        explanationEditorContent,
      } = this.state;

      // Process text for special characters
      const payload = {
        type: mode === "edit" ? "edit" : "add",
        questionText: this.processText(questionEditorContent),
        op1: this.processText(op1EditorContent),
        op2: this.processText(op2EditorContent),
        op3: this.processText(op3EditorContent),
        op4: this.processText(op4EditorContent),
        op1Num: optionIds[0] || 0,
        op2Num: optionIds[1] || 0,
        op3Num: optionIds[2] || 0,
        op4Num: optionIds[3] || 0,
        selectedCat: selectedCategory,
        correctOption,
        qNum: this.props.questionId || 0,
        quizId: "0",
        op5: num,
        op5Num: 0,
        uid,
        explanation: this.processText(explanationEditorContent),
        isEnglishMedium: this.props.isEnglishMedium ? "1" : "0",
      };

      const response = await axios.post(
        `${commonData["api"]}/moderator/add-edit-question`,
        payload,
        {
          headers: {
            authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data && !response.data.error) {
        NotificationManager.success(
          mode === "edit"
            ? "Question updated successfully"
            : "Question created successfully"
        );
        this.props.onClose(true);
        this.setState({
          isLoading: false,
          questionText: "",
          questionEditorContent: "",
          op1: "",
          op1EditorContent: "",
          op2: "",
          op2EditorContent: "",
          op3: "",
          op3EditorContent: "",
          op4: "",
          op4EditorContent: "",
          correctOption: 1,
          selectedCategory: "",
          categories: [],
          mode: "add", // new state property
          optionIds: [],
          explanation: "",
          explanationEditorContent: "",
          isEnglishMedium: false,
        });
      } else {
        throw new Error(
          response.data?.message ||
            `${mode === "edit" ? "Update" : "Create"} failed`
        );
      }
    } catch (error) {
      console.error(`${this.state.mode} error:`, error);
      NotificationManager.error(
        error.response?.data?.message || `Failed to ${this.state.mode} question`
      );
    }
  };

  render() {
    const { open, onClose } = this.props;

    return (
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        disableEscapeKeyDown
        disableBackdropClick
        hideBackdrop={false}
      >
        <DialogTitle>
          {this.props.isEnglishMedium ? " (EM) " : " (TM) "}{" "}
          {this.state.mode === "edit" ? "Edit Question" : "Create Question"}
        </DialogTitle>
        <DialogContent>
          {/* Question Editor */}
          <div style={{ marginBottom: 24 }}>
            <label
              style={{ display: "block", marginBottom: 8, fontWeight: 500 }}
            >
              Question
            </label>
            <Editor
              initialValue={this.state.questionText}
              onEditorChange={(content) =>
                this.setState({ questionEditorContent: content })
              }
            />
          </div>

          {/* Options */}
          {[
            { label: "Option 1", value: "op1" },
            { label: "Option 2", value: "op2" },
            { label: "Option 3", value: "op3" },
            { label: "Option 4", value: "op4" },
          ].map((option) => (
            <div key={option.value} style={{ marginBottom: 24 }}>
              <label
                style={{ display: "block", marginBottom: 8, fontWeight: 500 }}
              >
                {option.label}
              </label>
              <Editor
                initialValue={this.state[option.value]}
                onEditorChange={(content) =>
                  this.setState({ [`${option.value}EditorContent`]: content })
                }
              />
            </div>
          ))}

          {/* Correct Answer */}
          <div style={{ marginBottom: 24 }}>
            <label
              style={{ display: "block", marginBottom: 8, fontWeight: 500 }}
            >
              Correct Answer
            </label>
            <select
              value={this.state.correctOption}
              onChange={(e) => this.setState({ correctOption: e.target.value })}
              style={{ width: "100%", padding: 10 }}
            >
              <option value={1}>Option 1</option>
              <option value={2}>Option 2</option>
              <option value={3}>Option 3</option>
              <option value={4}>Option 4</option>
            </select>
          </div>

          {/* Category */}
          <div style={{ marginBottom: 24 }}>
            <label
              style={{ display: "block", marginBottom: 8, fontWeight: 500 }}
            >
              Category
            </label>
            <select
              value={this.state.selectedCategory}
              onChange={(e) =>
                this.setState({ selectedCategory: e.target.value })
              }
              style={{ width: "100%", padding: 10 }}
            >
              <option value="">Select Category</option>
              {this.state.categories.map((cat) => (
                <option key={cat.cid} value={cat.cid}>
                  {cat.category_name}
                </option>
              ))}
            </select>
          </div>

          {/* Explanation Editor */}
          <div style={{ marginBottom: 24 }}>
            <label
              style={{ display: "block", marginBottom: 8, fontWeight: 500 }}
            >
              Explanation (Optional)
            </label>
            <Editor
              initialValue={this.state.explanation}
              onEditorChange={(content) =>
                this.setState({ explanationEditorContent: content })
              }
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onClose(false);
              this.setState({
                isLoading: false,
                questionText: "",
                questionEditorContent: "",
                op1: "",
                op1EditorContent: "",
                op2: "",
                op2EditorContent: "",
                op3: "",
                op3EditorContent: "",
                op4: "",
                op4EditorContent: "",
                correctOption: 1,
                selectedCategory: "",
                categories: [],
                mode: "add", // new state property
                optionIds: [],
                explanation: "",
                explanationEditorContent: "",
                isEnglishMedium: false,
              });
            }}
          >
            Cancel
          </Button>
          <Button onClick={this.handleSubmit} color="primary">
            {this.state.mode === "edit" ? "Update" : "Create"} Question
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditQuestion;
