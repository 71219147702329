// import logo = lazy(() => import(".logo.svg";
import "./App.css";
import { useEffect, useState, Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Cookie from "js-cookie";
import commonData from "./importanValue";
import OneSignal from "react-onesignal";
import ProtectedRoute from "./components/ProtectedRoute";
import sendPushAdmin from "./components/admin/Sendpush";
import Loader from "./components/Loader";
import Notifications from "./components/main/Notifications";
import SubjectSelection from "./components/subjects";
import PromoteMessageComponent from "./components/admin/Promote";
import TodayExams from "./components/main/TodayExams";
import BookOrder from "./components/admin/BookOrders";
import MessageSMSComponent from "./components/admin/Promote-sms";
import UserQuestions from "./components/main/UserQuestions";
import UserExams from "./components/main/UserExams";
// import ReferAdmin from "./components/admin/ReferAdmin";
const HomePage = lazy(() => import("./components/HomePage"));
const NotFound = lazy(() => import("./components/NotFound"));
const Register = lazy(() => import("./components/Register"));
const LoginHome = lazy(() => import("./components/LoginHome"));
const DailyCA = lazy(() => import("./components/main/DailyCA"));
const EditUserProfile = lazy(() => import("./components/main/EditUserProfile"));
const ExamSchedules = lazy(() => import("./components/main/ExamSchedules"));
const ExamsList = lazy(() => import("./components/main/ExamsList"));
const BucketExamsList = lazy(() => import("./components/main/BucketExamsList"));
const ResultsList = lazy(() => import("./components/main/ResultsList"));
const Syllabus = lazy(() => import("./components/main/Syllabus"));
const ExamDetails = lazy(() => import("./components/main/ExamDetails"));
const ExamDetailsPaid = lazy(() => import("./components/main/ExamDetailsPaid"));

const AttemptQuiz = lazy(() => import("./components/main/AttemptQuiz"));
const ViewResult = lazy(() => import("./components/main/ViewResult"));
const Payumoney = lazy(() => import("./components/main/Payumoney"));
const PaymentFailed = lazy(() => import("./components/PaymentFailed"));
const Dashboard = lazy(() => import("./components/admin/Dashboard"));
const UserList = lazy(() => import("./components/admin/UserList"));
const ReferAdmin = lazy(() => import("./components/admin/ReferAdmin"));

const ReportedQuestions = lazy(() =>
  import("./components/admin/ReportedQuestions")
);
const Questions = lazy(() => import("./components/admin/Questions"));
const AddNewPackage = lazy(() => import("./components/admin/AddNewPackage"));
const AdminResultsList = lazy(() =>
  import("./components/admin/AdminResultList")
);
const CategoryList = lazy(() => import("./components/admin/CategoryList"));
const Coupons = lazy(() => import("./components/admin/Coupons"));
const AdminExamsList = lazy(() => import("./components/admin/ExamsList"));
const AddNewExam = lazy(() => import("./components/admin/AddNewExam"));
const Links = lazy(() => import("./components/admin/Links"));
// import Notifier from "./components/Notifier";

const ExportPaymentList = lazy(() =>
  import("./components/admin/ExportPaymentList")
);
const AutoResponder = lazy(() => import("./components/admin/AutoResponder"));
const Support = lazy(() => import("./components/main/Support"));
const userWallet = lazy(() => import("./components/main/UserWallet"));
 
// import PushNotifications from "./components/admin/PushNotification";
function App() {
  // const token = Cookie.get("jwt_token");
  // const uid = localStorage.getItem("uid");

  const token = Cookie.get("jwt_token");

  useEffect(async () => {
    await OneSignal.init({ appId: commonData["app_id"] });
  });

  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div className="loader-main-container">
            <Loader />
          </div>
        }
      >
        <Switch>
        <ProtectedRoute
            path="/moderator/question-bank"
            exact
            component={UserQuestions}
          />
          <ProtectedRoute
            path="/notifications"
            exact
            component={Notifications}
          />
          <ProtectedRoute
            path="/send-sms-messages"
            exact
            component={MessageSMSComponent}
          />
          <ProtectedRoute path="/book-orders" exact component={BookOrder} />
          <Route path="/select-the-exams" exact component={SubjectSelection} />
          <ProtectedRoute
            path="/payumoney/payment/checkout/:gid/:bucketId"
            exact
            component={Payumoney}
          />
           <ProtectedRoute
            path="/package/:gid/:bucketId"
            exact
            component={Payumoney}
          />
          <Route path="/payment-failed" exact component={PaymentFailed} />
          {/* <ProtectedRoute path="" exact component={HomePage} /> */}
          <ProtectedRoute
            path={[
              "/",
              "/index.php/login",
              "/index.html",
              "/login/registration/",
            ]}
            exact
            component={HomePage}
          />
          <ProtectedRoute
            path="/exams-list/:guid/:bucketId"
            exact
            component={ExamsList}
          />
          <ProtectedRoute
            path="/send-messages"
            exact
            component={PromoteMessageComponent}
          />
          <ProtectedRoute
            path="/exams-list/:guid/:bucketId/aG9tZQ=="
            exact
            component={BucketExamsList}
          />
          <ProtectedRoute
            path="/exam-details/:quid"
            exact
            component={ExamDetails}
          />
          <ProtectedRoute
            path="/exam-link/:quid"
            exact
            component={ExamDetailsPaid}
          />
          <ProtectedRoute
            path="/attempt-exam/:quid"
            exact
            component={AttemptQuiz}
          />
          <ProtectedRoute
            path="/view-result/:uid/:quizid/:resultid"
            exact
            component={ViewResult}
          />
          <ProtectedRoute
            path={["/results-list", "/index.php/result/"]}
            exact
            component={ResultsList}
          />
          <ProtectedRoute
            path="/user-profile"
            exact
            component={EditUserProfile}
          />
          {/* {uid === undefined ? (
          <Route path="/login" exact component={LoginHome} />
        ) : (
          token !== undefined && <Redirect to="/" />
        )} */}
          <Route path="/login" exact component={LoginHome} />
          <Route path="/todays-exams" exact component={TodayExams} />
          <ProtectedRoute
            path={["/exams-schedules", "/app/schedule.html"]}
            exact
            component={ExamSchedules}
          />
          <ProtectedRoute
            path={["/syllabus-in-telugu", "/app/syllabus.html"]}
            exact
            component={Syllabus}
          />
          <ProtectedRoute
            path={[
              "/study-materials",
              "/app/dailyca.html",
              "/app/materials.html",
            ]}
            exact
            component={DailyCA}
          />
          {/* {uid === undefined ? (
          <Route path="/register-new-account" exact component={Register} />
        ) : (
          <Redirect to="/login" />
        )} */}
          <ProtectedRoute path="/admin/users" exact component={UserList} />
          <ProtectedRoute path="/admin/dashboard" exact component={Dashboard} />
          <ProtectedRoute
            path="/admin/qbank/view-all"
            exact
            component={Questions}
          />
          <ProtectedRoute
            path="/admin/qbank/reported-questions/:quizid/:id"
            exact
            component={ReportedQuestions}
          />
          <ProtectedRoute
            path="/admin/qbank/categories"
            exact
            component={CategoryList}
          />
          <ProtectedRoute
            path="/admin/group/add-new-package"
            exact
            component={AddNewPackage}
          />
          <ProtectedRoute
            path="/admin/result/view-all"
            exact
            component={AdminResultsList}
          />
          <ProtectedRoute
            path="/admin/others/coupons"
            exact
            component={Coupons}
          />
          <ProtectedRoute
            path="/admin/exams/view-all"
            exact
            component={AdminExamsList}
          />
          <ProtectedRoute
            path="/admin/exams/add-edit-exam/:examid"
            exact
            component={AddNewExam}
          />
          <ProtectedRoute
            path="/admin/others/all-links/:category"
            exact
            component={Links}
          />
          <ProtectedRoute
            path="/admin/others/send-push"
            exact
            component={sendPushAdmin}
          />
          <ProtectedRoute
            path="/admin/others/auto-responder"
            exact
            component={AutoResponder}
          />
          <ProtectedRoute
            path="/admin/user/referdata"
            exact
            component={ReferAdmin}
          />
          <Route path="/register-new-account" exact component={Register} />
          <Route
            path="/export-payment-data/:date"
            exact
            component={ExportPaymentList}
          />
          <Route path="/not-found" exact component={NotFound} />
          <ProtectedRoute path="/support/user/:id" exact component={Support} />
          <ProtectedRoute
            path="/user/user-wallet"
            exact
            component={userWallet}
          />
          <ProtectedRoute path="/support" exact component={Support} />
          <ProtectedRoute
            path="/moderator/exam-bank"
            exact
            component={UserExams}
          />
          <Redirect to="/not-found" />
        </Switch>

        {/* <Support /> */}
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
